import { Delete, Edit, Email } from "@mui/icons-material";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ReactElement, useCallback, useEffect, useState } from "react";
import {
  apiDeleteNotificationInfo,
  apiGetNotificationInfos,
  apiSaveNotificationInfo,
  apiSendRemindersForDivision,
} from "../api";
import { NotificationInfo } from "../types";
import { formatDateOnly, formatDateTime } from "../utils";
import ConfirmDialog from "./ConfirmDialog";
import DivisionDialog from "./DivisionDialog";

export default function DivisionsPage(): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [isStoring, setIsStoring] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [notificationInfos, setNotificationInfos] = useState<
    NotificationInfo[]
  >([]);

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [divisionToEdit, setDivisionToEdit] = useState<NotificationInfo>();

  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const [divisionToEmail, setDivisiontoEmail] = useState<NotificationInfo>();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [divisionToDelete, setDivisionToDelete] = useState<NotificationInfo>();

  const load = useCallback(() => {
    setIsLoading(true);
    apiGetNotificationInfos()
      .then((data) => {
        setIsLoading(false);
        setNotificationInfos(data);
      })
      .catch((e) => {
        setIsLoading(false);
        setErrorMessage(e.message);
      });
  }, []);

  useEffect(() => load(), [load]);

  const saveItem = (divisionCode: string, sendEmailsFrom: string) => {
    setIsStoring(true);
    apiSaveNotificationInfo(divisionCode, sendEmailsFrom)
      .then(() => {
        setIsStoring(false);
        load();
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const sendEmails = (divisionCode: string) => {
    setIsStoring(true);
    apiSendRemindersForDivision(divisionCode)
      .then(() => {
        setIsStoring(false);
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  const deleteItem = (divisionCode: string) => {
    setIsStoring(true);
    apiDeleteNotificationInfo(divisionCode)
      .then(() => {
        setIsStoring(false);
        load();
      })
      .catch((e) => {
        setIsStoring(false);
        setErrorMessage(e.message);
      });
  };

  return (
    <Container maxWidth="lg">
      <Backdrop open={isLoading || isStoring} style={{ zIndex: 100 }}>
        <CircularProgress />
      </Backdrop>
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      <DivisionDialog
        open={isEditDialogOpen}
        divisionCode={divisionToEdit?.divisionCode || ""}
        divisionName={divisionToEdit?.divisionName || ""}
        sendEmailsFrom={divisionToEdit?.sendEmailsFrom || ""}
        onConfirm={(sendEmailsFrom) => {
          setIsEditDialogOpen(false);
          saveItem(divisionToEdit?.divisionCode || "", sendEmailsFrom);
        }}
        onDismiss={() => {
          setIsEditDialogOpen(false);
        }}
      />
      <ConfirmDialog
        open={isEmailDialogOpen}
        title="Send reminder emails"
        message={`Reminder emails will be sent to employees of division ${divisionToEmail?.divisionName} (${divisionToEmail?.divisionCode}) if sending is enabled for today. Are you sure?`}
        confirm="Yes"
        dismiss="No"
        invertButtonColors={true}
        onConfirm={() => {
          setIsEmailDialogOpen(false);
          sendEmails(divisionToEmail?.divisionCode || "");
        }}
        onDismiss={() => {
          setIsEmailDialogOpen(false);
        }}
      />
      <ConfirmDialog
        open={isDeleteDialogOpen}
        title="Delete dates"
        message={`Dates for division ${divisionToDelete?.divisionName} (${divisionToDelete?.divisionCode}) will be deleted. Are you sure?`}
        confirm="Yes"
        dismiss="No"
        invertButtonColors={true}
        onConfirm={() => {
          setIsDeleteDialogOpen(false);
          deleteItem(divisionToDelete?.divisionCode || "");
        }}
        onDismiss={() => {
          setIsDeleteDialogOpen(false);
        }}
      />
      <TableContainer component={Paper}>
        <Table size="small" padding="normal">
          <TableHead>
            <TableRow>
              <TableCell>Division Code</TableCell>
              <TableCell>Division Name</TableCell>
              <TableCell>Send Emails From</TableCell>
              <TableCell>Send Emails To</TableCell>
              <TableCell>Completion Date</TableCell>
              <TableCell>Updated By</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notificationInfos.map((row) => (
              <TableRow key={row.divisionCode}>
                <TableCell>{row.divisionCode}</TableCell>
                <TableCell>{row.divisionName}</TableCell>
                <TableCell>{formatDateOnly(row.sendEmailsFrom)}</TableCell>
                <TableCell>{formatDateOnly(row.sendEmailsTo)}</TableCell>
                <TableCell>{formatDateOnly(row.completionDate)}</TableCell>
                <TableCell>{row.updatedBy}</TableCell>
                <TableCell>{formatDateTime(row.updatedAt)}</TableCell>
                <TableCell>
                  <Tooltip title="Edit">
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={!row.canUpdate}
                      onClick={() => {
                        setDivisionToEdit(row);
                        setIsEditDialogOpen(true);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Send emails">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => {
                        setDivisiontoEmail(row);
                        setIsEmailDialogOpen(true);
                      }}
                    >
                      <Email />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      size="small"
                      color="primary"
                      disabled={!row.canUpdate || !row.sendEmailsFrom}
                      onClick={() => {
                        setDivisionToDelete(row);
                        setIsDeleteDialogOpen(true);
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
