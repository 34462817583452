import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { ReactElement } from "react";

export interface ConfirmDialogProps {
  open: boolean;
  title: string;
  message: string;
  confirm: string;
  dismiss: string;
  invertButtonColors?: boolean;
  onConfirm: () => void;
  onDismiss: () => void;
}

export default function ConfirmDialog(props: ConfirmDialogProps): ReactElement {
  return (
    <Dialog maxWidth="sm" open={props.open} onClose={props.onDismiss}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color={props.invertButtonColors ? "secondary" : "primary"}
          onClick={() => props.onConfirm()}
        >
          {props.confirm}
        </Button>
        <Button
          color={props.invertButtonColors ? "primary" : "secondary"}
          onClick={() => props.onDismiss()}
        >
          {props.dismiss}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
