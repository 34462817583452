export interface CurrentUser {
  email: string;
  isManager: boolean;
}

export interface Employee {
  code: string;
  name: string;
  email: string;
  comments: string;
  managerComments: string;
  date: string;
  hasPendingAcknowledgement: boolean;
  submitEnabled: boolean;
  manager: boolean;
  division: string;
  executive: boolean;
}

export const EmptyEmployee: Employee = {
  code: "",
  name: "",
  email: "",
  comments: "",
  managerComments: "",
  date: "",
  hasPendingAcknowledgement: false,
  submitEnabled: false,
  manager: false,
  division: "",
  executive: false,
};

export interface NotificationInfo {
  divisionCode: string;
  divisionName: string;
  sendEmailsFrom: string;
  sendEmailsTo: string;
  completionDate: string;
  updatedBy: string;
  updatedAt: string;
  canUpdate: boolean;
}
