import { FormControl, MenuItem, Select } from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { apiGetYears } from "../api";

interface SelectYearProps {
  year: number;
  onChange: (year: number) => void;
}

export default function SelectYear(props: SelectYearProps): ReactElement {
  const [years, setYears] = useState<number[]>([]);

  useEffect(() => {
    apiGetYears()
      .then((data) => {
        setYears(data);

        if (data.length > 0) {
          props.onChange(data[0]);
        }
      })
      .catch((e) => {
        setYears([]);
        console.error(e.message);
      });

    // eslint-disable-next-line
  }, []);

  return (
    <FormControl size="small">
      <Select
        value={props.year}
        onChange={(e) => props.onChange(e.target.value as number)}
      >
        {years.map((year) => (
          <MenuItem value={year}>{year}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
