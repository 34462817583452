import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { dateToISODate } from "../utils";

interface DivisionDialogProps {
  open: boolean;
  divisionCode: string;
  divisionName: string;
  sendEmailsFrom: string;
  onConfirm: (sendEmailsFrom: string) => void;
  onDismiss: () => void;
}

export default function DivisionDialog(
  props: DivisionDialogProps,
): ReactElement {
  const [sendEmailsFrom, setSendEmailsFrom] = useState(props.sendEmailsFrom);

  useEffect(() => {
    setSendEmailsFrom(props.sendEmailsFrom);
  }, [props.sendEmailsFrom]);

  return (
    <Dialog fullWidth maxWidth="xs" open={props.open} onClose={props.onDismiss}>
      <DialogTitle>Edit Division</DialogTitle>
      <DialogContent dividers>
        <TextField
          InputLabelProps={{ shrink: true }}
          disabled
          fullWidth
          margin="normal"
          label="Division Code"
          value={props.divisionCode}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          disabled
          fullWidth
          margin="normal"
          label="Division Name"
          value={props.divisionName}
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          fullWidth
          required
          margin="normal"
          type="date"
          label="Send Emails From"
          value={dateToISODate(sendEmailsFrom)}
          onChange={(e) => setSendEmailsFrom(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => props.onConfirm(sendEmailsFrom)}>
          Save
        </Button>
        <Button color="primary" onClick={props.onDismiss}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
