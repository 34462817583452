export function dateToISODate(date: string): string {
  if (date) {
    return new Date(date).toISOString().split("T")[0];
  }
  return "";
}

export function formatDateOnly(date: string): string {
  if (date) {
    return new Date(date).toLocaleDateString();
  }
  return "-";
}

export function formatDateTime(date: string): string {
  if (date) {
    return new Date(date).toLocaleString();
  }
  return "-";
}
