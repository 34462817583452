import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import { ReactElement } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { msalInstance } from "../auth";
import theme from "../theme";
import DivisionsPage from "./DivisionsPage";
import EmployeesPage from "./EmployeesPage";
import Header from "./Header";
import HomePage from "./HomePage";
import Login from "./Login";

export default function Application(): ReactElement {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MsalProvider instance={msalInstance}>
        <BrowserRouter>
          <Header onLogoutClick={() => msalInstance.logoutRedirect()} />

          <UnauthenticatedTemplate>
            <Login onLoginClick={() => msalInstance.loginRedirect()} />
          </UnauthenticatedTemplate>

          <AuthenticatedTemplate>
            <Box m={3}>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/employees" element={<EmployeesPage />} />
                <Route path="/emails" element={<DivisionsPage />} />
              </Routes>
            </Box>
          </AuthenticatedTemplate>
        </BrowserRouter>
      </MsalProvider>
    </ThemeProvider>
  );
}
